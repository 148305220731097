import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mh-core-lib-simple-date-imput',
  templateUrl: './simple-date-imput.component.html',
  styleUrls: ['./simple-date-imput.component.scss'],
})
export class CoreLibSimpleDateImputComponent {
  @Input() minDate: string;
  @Input() maxDate: string;
  @Output() dateChange = new EventEmitter<string>();

  onDateChange(event: Event) {
    const dateValue = (event.target as HTMLInputElement).value;
    this.dateChange.emit(dateValue);
  }
}
