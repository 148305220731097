import { Language } from "../language.model";


export class InputType{
  id:number = 1;
  type:string = "text";
  constructor(id=1, type="text"){
    this.id = id;
    this.type = type;
  }
}

export class InputTranslate{
  id:number;
  language_id:number;
  languageId?:number;
  label:string='';
  constructor(language_id){
    this.language_id = language_id;
  }
}

export class InputTranslateLanguage{
  id:number;
  language:Language;
  text:string='';
  constructor(language){
    this.language = language;
  }
}

export class InputAlternatives{
  id:number;
  sort=0;
  translates:InputTranslate[] = [];
}

export class InputField{
  id:number;
  input_alternatives: InputAlternatives[] = [];
  input_type:InputType = new InputType();
  required:boolean = true;
  sort:number = 1;
  step:number = 1;
  grid:number = 6;
  translates:InputTranslate[] = [];
  label='';
  companion_id?: number

  setLabel(languageId){
    let translate = this.translates.find(x=>x.language_id===languageId);
    if(translate){
      this.label = translate.label;
    }else{
      this.label = this.translates.length > 0 ? this.translates[0].label : '';
    }
  }

}


