<div>
    <label *ngIf="label" for="timeSelector" class="tw-text-main-text tw-text-xs">
        {{ label }}
    </label>
    <input 
        type="time" 
        id="timeSelector" 
        class="tw-bg-white tw-border tw-border-border-light-gray tw-cursor-pointer tw-mb-3 tw-min-h-[50px] tw-p-2 tw-rounded-xl" 
        name="timeSelector"
        (change)="onTimeChange($event)"
    >
</div>
