<mh-card-user-thumbnail [action]="log" [titleKey]="'company_position'">
  <div class="action-detail" header>
    <ng-container *ngIf="!isReviewLog else headerTitle">
      <ng-container *ngIf="isReportLog">{{ ('logs.resources.reports.title' | translate)}}: <strong mhFindTranslate [language]="language" keyTranlsate="reportName"
        [translates]="log.changes.reportNameTranslates" [capitalize]="true"></strong></ng-container>
      <ng-container *ngIf="!isReportLog">{{ ('logs.actions.'+log.type | translate)}}: <strong>{{'logs.resources.' + log.resourceName + '.title' | translate}}</strong></ng-container>
    </ng-container>
  </div>
  <ng-container body>
    <ng-conatiner *ngIf="!isReportLog">
      {{'logs.changes_description' | translate}}:
      <ng-container
        *ngTemplateOutlet="log.category === 'CUSTOMIZE_CONFIG' ? changesDescription :  changesList, context: { changes: log.changes, log: log }"
      >
      </ng-container>
    </ng-conatiner>
    <ng-container *ngIf="isReportLog">
      <ng-container
        *ngTemplateOutlet="reportLog, context: { changes: log.changes, log: log, language: language }"
      >
      </ng-container>
    </ng-container>
  </ng-container>
</mh-card-user-thumbnail>

<ng-template #headerTitle>
  <ng-container *ngIf="isReviewLog">
    <ng-container *ngTemplateOutlet="headerReview"></ng-container>
  </ng-container>
  <ng-container *ngIf="isReviewManaged || isSurveyAnsweredDeleted">
    <ng-container *ngTemplateOutlet="manageAnsweredSurvey"></ng-container>
  </ng-container>
</ng-template>

<ng-template #headerReview>
  {{'logs.actions.replied' | translate}}: <strong>{{'logs.resources.' + log.resourceName + '.title' | translate}} - {{log.changes[0].review_id }}</strong>
</ng-template>

<ng-template #manageAnsweredSurvey>
  {{'logs.actions.'+ (isReviewManaged ? 'managed' : 'deleted') | translate}}: <strong>{{'logs.resources.answered_survey.title' | translate}} - {{log.changes[0].answered_survey_id}}</strong>
</ng-template>


<ng-template #changesDescription>
  <p *ngFor="let change of log.changes"> {{ change?.description }} </p>
</ng-template>

<ng-template #changesList
  let-changes="changes"
  let-log="log">
  <ul>
    <ng-container *ngFor="let change of changes">
      <li *ngIf="!EXCLUDED_FIELDS.includes(change?.name)">
        <ng-container *ngIf="change.actual?.translates && !isSentiment; else baseChange">
          <ng-template *ngTemplateOutlet="commonLog, context: { change, log, language }"></ng-template>
        </ng-container>
        <ng-template #baseChange>
          <ng-container *ngIf="log.resourceName !== 'review_reply' && change.name !== 'translation'; else translation">
            <ng-container *ngIf="isReviewManaged || isSurveyAnsweredDeleted || isReprocessSurvey || isSentiment; else base">
              <div *ngIf="isReviewManaged" [innerHtml]="'logs.actions.managed' | translate:{name: userManage, date: (dateManage | date:'dd-MM-y'), answeredId: surveyIdManage}"></div>
              <div *ngIf="isSurveyAnsweredDeleted" [innerHtml]="'logs.actions.deleted' | translate:{name: userManage, answeredId: surveyIdManage}"></div>
              <div *ngIf="isReprocessSurvey" [innerHtml]="'logs.actions.reprocessed' | translate: { surveyName: change.surveyTypeName, startDate: (change.startDate | date: 'dd-MM-y'), endDate: (change.endDate | date: 'dd-MM-y')  }"></div>
              <div *ngIf="isSentiment">
                <ng-template *ngTemplateOutlet="sentimentLog, context: { change, log, language }"></ng-template>
              </div>
            </ng-container>
            <ng-template #base>
              {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
              <span
                [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParam(change)))"
                >
              </span>
            </ng-template>
          </ng-container>
          <ng-template #translation>
            <ng-container *ngIf="log.resourceName !== 'review_reply' else translateReviewLog">
              {{'logs.resources.' + log.resourceName + '.fields.' + change.actual.fieldName | translate}}:
              <span
                [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParamForTranslation(change)))"
                >
              </span>
            </ng-container>
          </ng-template>
          <ng-template #translateReviewLog>
              {{'logs.resources.' + log.resourceName + '.review_replied' | translate}}:
              <span>
                {{change.description}}
              </span>
          </ng-template>
        </ng-template>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #sentimentLog let-change="change" let-log="log" let-language="language">
  <strong mhFindTranslate
    [language]="language"
    [translates]="change.actual?.translates"
    [keyTranlsate]="'text'"
    [capitalize]="true"
  ></strong>:
  <span
    [innerHtml]="('logs.changes.' + change.operation + '.' + change.type | translate: (getTranslateParam(change)))"
  ></span>
</ng-template>

<ng-template #commonLog let-change="change" let-log="log" let-language="language">
  {{'logs.resources.' + log.resourceName + '.fields.' + change.name | translate}}:
  <strong mhFindTranslate
    [language]="language"
    [translates]="change.actual?.translates"
    [keyTranlsate]="'name'"
    [capitalize]="true"
  ></strong>
</ng-template>

<ng-template #reportLog let-changes="changes" let-log="log" let-language="language">
  <ul>
    <li>
      <strong>{{'logs.resources.reports.download_date' | translate}}:</strong> {{changes.downloadDate | date:'dd-MM-y'}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.name' | translate}}:</strong>
      <span mhFindTranslate [language]="language" keyTranlsate="reportName"
        [translates]="changes.reportNameTranslates" [capitalize]="true"></span>
    </li>
    <li>
      <strong>{{'logs.resources.reports.range' | translate}}:</strong>
      {{changes.reportRange.substring(1, 11) | date:'dd-MM-y'}} - {{changes.reportRange.substring(13, 23) | date:'dd-MM-y'}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.customer' | translate}}:</strong> {{changes.customerData.chainName}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.chain_id' | translate}}:</strong> {{changes.customerData.chainId}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.user' | translate}}:</strong> {{changes.userData.userName}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.user_id' | translate}}:</strong> {{changes.userData._id}}
    </li>
    <li>
      <strong>{{'logs.resources.reports.obtaining_by' | translate}}:</strong> {{changes.obtainingBy}}
    </li>
  </ul>
</ng-template>
